export default function OverView({getHazardIdenti, state}) {

    const _getDateTime = (date_time) => {
        let d = new Date(date_time),
            dformat = [d.getMonth() + 1,
            d.getDate(),
            d.getFullYear()].join('/') + ' ' +
                [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join(':');
        return dformat;
    }

    // const _sizeConversion = (bytes, decimals = 2) => {
    //     if (!+bytes) return '0 Bytes'

    //     const k = 1024
    //     const dm = decimals < 0 ? 0 : decimals
    //     const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    //     const i = Math.floor(Math.log(bytes) / Math.log(k))
    //     return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    // }

    const _totalTime = (end, start) => {
        const _end = new Date(end * 1000);
        const _start = new Date(start * 1000);
        const total = Math.abs(_end - _start);

        var d = new Date(1000 * Math.round(total / 1000));
        function pad(i) { return ('0' + i).slice(-2); }
        return pad(d.getUTCMinutes()) + ':' + pad(d.getUTCSeconds()) + 'Mins';
    }

    return (
        <div className="row">
        <div className="tile sm-12 md-12 lg-12 xl-12">
            <div className="header">
                <div className="left">
                    <div className="title">General Information</div>
                </div>
            </div>
            <div className="StaticInfoDetails">
                <div className="dataInfo">
                    <div className="dataLabel">ID: </div>
                    <div className="info">{state.id}</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Site ID: </div>
                    <div className="info">{getHazardIdenti.site_id}</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Site Type: </div>
                    <div className="info">{getHazardIdenti.site_type}</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Customer: </div>
                    <div className="info">{getHazardIdenti.customer}</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Date/Time: </div>
                    <div className="info">{_getDateTime(getHazardIdenti.created)}</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Survey Archive: </div>
                    <div className="info">{getHazardIdenti.archive.filename}</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Archive Size: </div>
                    <div className="info">{getHazardIdenti.size_in_mb} MB</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Image Count: </div>
                    <div className="info">{getHazardIdenti.archive.files.length}</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Total Time: </div>
                    <div className="info">{getHazardIdenti.completion_time} Mins</div>
                </div>

                <div className="dataInfo">
                    <div className="dataLabel">Created By: </div>
                    <div className="info">{getHazardIdenti.full_name}</div>
                </div>
            </div>
        </div>

        <div className="tile sm-12 md-12 lg-12 xl-12">
            <div className="header">
                <div className="left">
                    <div className="title">Hazards Summary</div>
                </div>
            </div>
            <div className="StaticInfoDetails">
                <p>{getHazardIdenti.summarized_data.final_summary}</p>
            </div>
        </div>
    </div>
    )
}