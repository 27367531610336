import { Routes, Route, Navigate } from "react-router-dom";
import UserStats from "./views/UserStats/UserStats";
import ReportStats from "./views/ReportStats/ReportStats";
import { JHAReports, SiteWalkReports } from "./views/Reports/Reports";
import SiteWalk from "./views/SiteWalk/SiteWalk";
import ChangePassword from "./layouts/Main/ChangePassword";
import Actions from "./views/Actions/Actions";
import Home from "./layouts/Home/Home";
import LoneWorker from "./views/LoneWorker/LoneWorker";
import Settings from "./views/Settings/Settings";
import EHSReports from "./views/EHSReports/EHSReports";
import UserManagement from "./views/UserManagement/UserManagement";
import Configuration from "./views/Configuration/Configuration";
import SiteWalkGoalsManagement from "./views/SiteWalkGoals/SiteWalkGoalsManagement";
import GoalsAnalytics from "./views/SiteWalkGoals/GoalsAnalytics";
import { useSelector } from "react-redux";
import SupportForm from "./layouts/support/SupportForm";
import Certification from "./views/Certification/Certification";
import AIConfiguration from "./views/AIInference/AIConfiguration";
import SuppliersManagement from "./views/SuppliersManagement/Suppliers";
import ViewMoreSupplier from "./views/SuppliersManagement/ViewMoreInfo";
import PrivateRoute from "./PrivateRoute";
import Incidents from "./views/Incidents/Incidents";
import Roles from "./views/Roles/Roles";
import SafetyMetrics from "./views/SafetyMetrics/SafetyMetrics";
import HazardID from "./views/HazardID/HazardID";
import AllSession from "./views/LoneWorker/AllSession";
import ViewDetails from "./views/HazardID/ViewDetails";

export default function Routing() {
  const isNewUser = useSelector((state) => state.loggedInUser.isNewUser);
  const policyStatus = useSelector((state) => state.loggedInUser.policyStatus);
  const enduserStatement = useSelector((state) => state.loggedInUser.enduserStatement);
  const usergroupId = useSelector(
    (state) => state.loggedInUser.user?.usergroupId
  );

  const newUserRoutes = (
    <>
      <Route exact path="/" element={<Navigate to="/change-password" />} />
      <Route exact path="/change-password" element={<ChangePassword />} />
      <Route exact path="*" element={<Navigate to="/change-password" />} />
    </>
  );

  const policyRoutes = (
    <>
      <Route exact path="/" element={<Navigate to="/home" />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="*" element={<Navigate to="/home" />} />
    </>
  );

  const authenticatedRoutes = (
    <>
      <Route exact path="/" element={<Navigate to="/home" />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/change-password" element={<ChangePassword />} />
      <Route
        exact
        path="/user-stats"
        element={
          <PrivateRoute
            roles={["CONSOLE_GLOBAL_ADMIN"]}
            children={<UserStats />}
          />
        }
      />

      <Route
        exact
        path="/JHA-report-stats"
        element={
          <PrivateRoute
            roles={[
              "CONSOLE_SAFETY_FORM_VIEWER",
              "CONSOLE_SAFETY_FORM_APPROVALS",
              "CONSOLE_SAFETYFORM_SUPPLIER"
            ]}
            children={<ReportStats />}
          />
        }
      />

      <Route
        key="JHA-Reports"
        exact
        path="/JHA-Reports"
        element={
          <PrivateRoute
            roles={[
              "CONSOLE_SAFETY_FORM_VIEWER",
              "CONSOLE_SAFETY_FORM_APPROVALS",
              "CONSOLE_SAFETYFORM_SUPPLIER"
            ]}
            children={<JHAReports />}
          />
        }
      />

      <Route
        key="Sitewalk-Reports"
        exact
        path="/Sitewalk-Reports"
        element={
          <PrivateRoute
            roles={[
              "CONSOLE_SITE_SAFETYFORM_VIEWER",
              "CONSOLE_SITE_SAFETYFORM_APPROVALS",
              "CONSOLE_SAFETYFORM_SUPPLIER"
            ]}
            children={
              usergroupId === "1" ? <EHSReports /> : <SiteWalkReports />
            }
          />
        }
      />

      <Route
        exact
        path="/Sitewalk-Reports/:reportId"
        element={
          <PrivateRoute
            roles={[
              "CONSOLE_SITE_SAFETYFORM_VIEWER",
              "CONSOLE_SITE_SAFETYFORM_APPROVALS",
              "CONSOLE_SAFETYFORM_SUPPLIER"
            ]}
            children={
              usergroupId === "1" ? <EHSReports /> : <SiteWalkReports />
            }
          />
        }
      />

      <Route
        exact
        path="/site-walk"
        element={
          <PrivateRoute
            roles={[
              "CONSOLE_SITE_SAFETYFORM_VIEWER",
              "CONSOLE_SITE_SAFETYFORM_APPROVALS",
              "CONSOLE_SAFETYFORM_SUPPLIER"
            ]}
            children={<SiteWalk />}
          />
        }
      />

      <Route
        exact
        path="/actions"
        element={
          <PrivateRoute
            roles={[
              "CONSOLE_SAFETYACTION_ASSIGNEE",
              "CONSOLE_SAFETYACTION_GLOBAL",
              "CONSOLE_GLOBAL_ADMIN",
              "CONSOLE_SAFETYACTION_SUPPLIER"
            ]}
            children={<Actions />}
          />
        }
      />

      <Route
        exact
        path="/certificates"
        element={
          <PrivateRoute
            roles={["CONSOLE_GLOBAL_ADMIN"]}
            children={<Certification />}
          />
        }
      />

      <Route
        exact
        path="/beacon-monitoring"
        element={
          <PrivateRoute
            children={<LoneWorker />}
            roles={[
              "CONSOLE_LONEWORKER_VIEWER",
              "CONSOLE_LONEWORKER_MANAGER",
              "CONSOLE_GLOBAL_ADMIN",
            ]}
          />
        }
      />

      <Route
        exact
        path="/beacon-monitoring/all_session"
        element={
          <PrivateRoute
            children={<AllSession />}
            roles={[
              "CONSOLE_LONEWORKER_VIEWER",
              "CONSOLE_LONEWORKER_MANAGER",
              "CONSOLE_GLOBAL_ADMIN",
            ]}
          />
        }
      />


      <Route
        exact
        path="/ai-agents/*"
        element={
          <PrivateRoute
            children={<AIConfiguration />}
            roles={["CONSOLE_GLOBAL_ADMIN",
              "APP_SAFETYFORMS",
              "APP_SAFETYFORMS_APPROVALS",
              "CONSOLE_SAFETYFORM_VIEWER",
              "CONSOLE_SAFETYFORM_APPROVALS"]}
          />
        }
      />

      <Route
        exact
        path="/site-hazards"
        element={
          <PrivateRoute
            children={<HazardID />}
            roles={["CONSOLE_HAZARD_ID_USER"]}
          />
        }
      />

      <Route
        exact
        path="/site-hazards/view-details"
        element={
          <PrivateRoute
            children={<ViewDetails />}
            roles={["CONSOLE_HAZARD_ID_USER"]}
          />
        }
      />

      <Route
        exact
        path="/contractors"
        element={
          <PrivateRoute
            children={<SuppliersManagement />}
            roles={["CONSOLE_GLOBAL_ADMIN"]}
          />
        }
      />

      <Route
        exact
        path="/contractors/:contractorID"
        element={
          <PrivateRoute
            children={<ViewMoreSupplier />}
            roles={["CONSOLE_GLOBAL_ADMIN"]}
          />
        }
      />

      <Route
        exact
        path="/smartfactory-settings"
        element={
          <PrivateRoute
            children={<Settings />}
            roles={["CONSOLE_SMARTFACTORY_SETTING"]}
          />
        }
      />

      <Route
        key="Users"
        exact
        path="/Users"
        element={
          <PrivateRoute
            children={<UserManagement />}
            roles={["CONSOLE_USER_ADMIN", "CONSOLE_GLOBAL_ADMIN"]}
          />
        }
      />

      <Route
        key="Roles"
        exact
        path="/Roles"
        element={
          <PrivateRoute
            children={<Roles />}
            roles={["CONSOLE_GLOBAL_ADMIN"]}
          />
        }
      />

      <Route
        exact
        path="/Sitewalk-Goals/Management"
        element={
          <PrivateRoute
            children={<SiteWalkGoalsManagement />}
            roles={[
              "CONSOLE_GOALS_VIEWER",
              "CONSOLE_GOALS_EDITOR",
              "CONSOLE_GLOBAL_ADMIN",
            ]}
          />
        }
      />

      <Route
        exact
        path="/Sitewalk-Goals/Analytics"
        element={
          <PrivateRoute
            children={<GoalsAnalytics />}
            roles={[
              "CONSOLE_GOALS_VIEWER",
              "CONSOLE_GOALS_EDITOR",
              "CONSOLE_GLOBAL_ADMIN",
            ]}
          />
        }
      />

      <Route
        exact
        path="/configuration/*"
        element={
          <PrivateRoute
            children={<Configuration />}
            roles={["CONSOLE_GLOBAL_ADMIN"]}
          />
        }
      />

      <Route exact path="/Get-Support" element={<SupportForm />} />

      <Route exact path="*" element={<Navigate to="/home" />} />
      <Route
        exact
        path="/incidents"
        element={
          <PrivateRoute
            children={<Incidents />}
            roles={["CONSOLE_GLOBAL_ADMIN"]}
          />
        }
      />
      <Route
        exact
        path="/safety-metrics"
        element={
          <PrivateRoute
            roles={["CONSOLE_SAFETY_METRICS_VIEWER"]}
            children={<SafetyMetrics />}
          />
        }
      />
    </>
  );

  return (
    <Routes>
      {/* If the logged in user is a new user, it will navigate to the Change password page. */}
      {isNewUser && newUserRoutes}

      {/* If the logged in user is an existing user and not aceepted the privacy policy, it will navigate to the Home page. */}
      {!isNewUser && (!policyStatus || !enduserStatement) && policyRoutes}
      {/* If the logged in user is an existing user and aceepted the privacy policy perviously, it will navigate to respected pages. */}
      {!isNewUser && (policyStatus || enduserStatement) && authenticatedRoutes}
      {/* If the aceesstoken is empty, it will navigate to the Login page. */}
    </Routes>
  );
}
