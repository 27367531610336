import React, { useState, useEffect, useRef } from "react";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import _ from "lodash";
import CustomDatePickerForm from "../../components/CustomDatePickerForm/CustomDatePickerForm";
import { useSelector } from "react-redux";
import {
  getLoneWorkerData,
  getLoneWorkerFilters,
  getPanicOverrun,
  getActiveMissChecking
} from "../../api/loneWorker.api";
import ActiveSession from "./ActiveSession";
import CompleteSession from "./CompleteSession";
import ActiveSessionTable from "./ActiveSessionTable";
import OpenAlertTable from "./OpenAlertTable";
import LoneWorkerMap from "./LoneWorkerMap";
import { Accordion } from '@eds/vanilla';
// import LoneWorkerTable from "./LoneWorkerTable";

export default function LoneWorker() {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    new Date(
      moment().local().subtract(1, "weeks").format("YYYY-MM-DD 00:00:00")
    )
  );
  const [endDate, setEndDate] = useState(
    new Date(
      moment()
        .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("YYYY-MM-DD 23:59:59")
    )
  );
  const [loadingVal, setLoading] = useState({
    loneWorkerData: true,
    panicOverrun: true,
    completedData: true,
    activeMissChecking: true,
    isMap: true,
  });
  const [getLocations, setLocations] = useState([]);
  const [errMsg, setErrMsg] = useState({
    loneWorkerData: "",
    panicOverrun: "",
    completedData: "",
    activeMissChecking: ""
  });
  const [getLoneData, setLoneData] = useState([]);
  const [getPanicOverrunData, setPanicOverrunData] = useState({});
  const [getActiveMissCheckingData, setActiveMissCheckingData] = useState({})
  const [getSessionCount, setSessionCount] = useState({})
  const [getMapData, setMapData] = useState([]);
  const [getLoneFilterData, setLoneFilterData] = useState({
    loading: false,
    data: {},
    message: "",
  });
  const [openPanic, setOpenPanic] = useState(true);
  const [openActiveTable, setOpenActiveTable] = useState(true);
  const [isAutoCall, setIsAutoCall] = useState(false);
  const { headers } = useSelector((state) => state.envVariables);
  const [opneOverrunCount, setOpneOverrunCount] = useState();

  // const MapData = useRef();

  let datepickerData = {
    startDate,
    setStartDate,
    // endDate,
    // setEndDate,
    // getStatusOption,
    // setStatusOption,
    getLocations,
    setLocations,
    getLoneFilterData,
  };

  useEffect(() => {
    const accordions = document.querySelectorAll('.accordion');

    if (accordions) {
      Array.from(accordions).forEach((accordionDOM) => {
        const accordion = new Accordion(accordionDOM);
        accordion.init();
      });
    }
  }, []);

  const PanicOverrun = async (param, status, acknowledge, isLodder, check) => {
    if (check === "All" || check === "PanicOverrun") {
      param.status = status;
      param.acknowledge = acknowledge;
      await getPanicOverrun(
        param,
        setLoadingHandler,
        setErrMsgHandler,
        setPanicOverrunData,
        setSessionCount,
        isLodder,
        headers
      );
    }
  }

  const ActiveMissChecking = async (param, status, acknowledge, isLodder, check) => {
    if (check === "All" || check === "ActiveMissChecking") {
      param.status = status;
      param.acknowledge = acknowledge;
      await getActiveMissChecking(
        param,
        setLoadingHandler,
        setErrMsgHandler,
        setActiveMissCheckingData,
        isLodder,
        headers
      );
    }
  }

  const AllSessionData = async (param, status, acknowledge, isLodder, check) => {
    if (check === "All") {
      param.status = status;
      param.acknowledge = acknowledge;
      await getLoneWorkerData(
        param,
        setLoadingHandler,
        setErrMsgHandler,
        setLoneData,
        setMapData,
        isLodder,
        headers
      );
    }
  }

  const callGetDeficiencies = (isLodder, check) => {
    let param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      // end_date: moment(endDate).format("YYYY-MM-DD"),
      // status: getStatusOption,
      // status:[2,4],
      state: getLocations,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      // acknowledge:["Y"]
    };
    if (
      !_.isNull(startDate) &&
      // !_.isNull(endDate) &&
      // getStatusOption.length &&
      getLocations.length
    ) {
      PanicOverrun(param, [3, 4], ["N"], isLodder, check);
      ActiveMissChecking(param, [1, 5], ["N"], isLodder, check);
      AllSessionData(param, [1, 2, 3, 4, 5], ["N,Y"], isLodder, check)


    } else {
      setPanicOverrunData({});
      let error = "";
      if (
        !getLocations.length &&
        !getLoneFilterData.data?.locations.length
      ) {
        error = "No data found under this date range";
      }
      setErrMsg((val) => ({
        ...val,
        panicOverrun: error,
        // loneWorkerMetrics: error,
      }));
      setLoading((val) => ({
        ...val,
        panicOverrun: false,
        // loneWorkerMetrics: false,
        // isMap: false,
      }));
    }
  };

  const setLoadingHandler = (val) => {
    setLoading((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
      [Object.keys(val)[1]]: Object.values(val)[1],
    }));
  };

  const setErrMsgHandler = (val) => {
    setErrMsg((prevState) => ({
      ...prevState,
      [Object.keys(val)[0]]: Object.values(val)[0],
    }));
  };

  useEffect(() => {
    const param = {
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(endDate).format("YYYY-MM-DD"),
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const searchURL = `?start_date=${param?.start_date}&end_date=${param?.end_date}&timeZone=${param?.timeZone}`;
    getLoneWorkerFilters(searchURL, setLoneFilterData, headers);

  }, []);

  useEffect(() => {
    if (!_.isEmpty(getLoneFilterData.data)) {
      // setStatusOption(getLoneFilterData.data?.status.map((val) => val.id));
      setLocations(getLoneFilterData.data?.locations || []);
      // setMetricsStatus(getLoneFilterData.data?.status.map((val) => val.id));
      setIsAutoCall(true);
    }
  }, [getLoneFilterData.data]);

  useEffect(() => {
    if (isAutoCall) {
      callGetDeficiencies(0, "All");
      setIsAutoCall(false);
    }
  }, [isAutoCall]);


  const AlertData = (alert) => {
    let data = alert === "Overrun-Session" ? "Major" : alert === "Miss Check-In" ? "Minor" : alert === "Active-Session" ? "Default" : alert === "Completed-Session" ? "maintenance" : alert === "Panic-Alert" && "Critical";
    return [{ name: data, value: alert === "Completed-Session" ? "Successful" : alert === "Active-Session" ? "Current" : alert }];
  };

  const getCord = (node, data) => {
    Object.values(data["Status"]).filter((i, k) => {
      node.push({
        key: k,
        name: data["Fullname"][k] + "<br>(" +
          parseFloat(data["Location"][k].split(",")[0]) +
          ", " +
          parseFloat(data["Location"][k].split(",")[1]) +
          ")",
        icon: "icon-profile",
        x: parseFloat(data["Location"][k].split(",")[1]),
        y: parseFloat(data["Location"][k].split(",")[0]),
        "maintenance": data["Status"][k] === "Completed-Session" ? true : false,
        status: data["Status"][k] === "Completed-Session" ? "Successful" : data["Status"][k] === "Active-Session" ? "Current" : data["Status"][k],
        alerts: AlertData(data["Status"][k]),
      });
    });
  }

  useEffect(() => {
    let node = [];
    if (!_.isEmpty(getLoneData)) {
      getCord(node, JSON.parse(getLoneData.loneworker_session_data));
      setMapData(node);
      // MapData.current = node;
    }
  }, [getLoneData]);

  useEffect(() => {
    if (!_.isEmpty(getPanicOverrunData) && _.isEmpty(JSON.parse(getPanicOverrunData)["Email"])) {
      setOpenPanic(false);
    } else {
      setOpenPanic(true);
    }
  }, [getPanicOverrunData]);

  useEffect(() => {
    if (!_.isEmpty(getActiveMissCheckingData) && _.isEmpty(JSON.parse(getActiveMissCheckingData)["Email"])) {
      setOpenActiveTable(false);
    } else {
      setOpenActiveTable(true);
    }
  }, [getActiveMissCheckingData]);

  const disableButtton = loadingVal.loneWorkerData || _.isNull(startDate) || !getLocations.length;



  return (
    <>

      <div className="row loneWorker">
        <div className="tile filterDiv">
          <CustomDatePickerForm
            formName="lone-worker"
            datepickerData={datepickerData}
            filterData={getLoneFilterData.data}
            callApply={() => {
              setIsAutoCall(true);
            }}
            disableButtton={disableButtton}
          // filtersAccess={filtersAccess}
          />
        </div>
        {/* <div className="row ActivePanicList">
          <ActivePanicMetrics />
        </div> */}
        {getLoneFilterData.loading ? (
          <Loading />
        ) : getLoneFilterData.message !== "Success" ? (
          <AlertEDS
            errMsg={getLoneFilterData.message}
            status_code={201}
            close={true}
          // closeAlert={closeAlert}
          />
        ) : (
          <>
            <div className="tile sm-12 md-12 lg-12 xl-12">
              <div className="header">
                <div className="left">
                  <div className="title"><div className="iconTitle"><i className='icon icon-document'></i><span>Active</span></div></div>
                </div>
              </div>
              <div className="content">
                <div className="column">
                  {loadingVal?.panicOverrun ? (
                    <Loading />
                  ) : (
                    <>
                      {errMsg.panicOverrun !== "Success" &&
                        errMsg.panicOverrun !==
                        "No data available in this date range" ? (
                        <AlertEDS
                          errMsg={errMsg.panicOverrun}
                          status_code={201}
                          close={false}
                        />
                      ) : (
                        <>
                          {!_.isEmpty(getSessionCount) && (
                            <ActiveSession
                              sessionCount={JSON.parse(getSessionCount)}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="tile sm-12 md-12 lg-12 xl-12">
              <div className="header">
                <div className="left">
                  <div className="title"><div className="iconTitle"><i className='icon icon-document'></i><span>Completed</span></div></div>
                </div>
              </div>
              <div className="content">
                <div className="column">
                  {loadingVal?.panicOverrun ? (
                    <Loading />
                  ) : (
                    <>
                      {errMsg.panicOverrun !== "Success" &&
                        errMsg.panicOverrun !==
                        "No data available in this date range" ? (
                        <AlertEDS
                          errMsg={errMsg.panicOverrun}
                          status_code={201}
                          close={false}
                        />
                      ) : (
                        <>
                          {!_.isEmpty(getSessionCount) && (
                            <CompleteSession
                              sessionCount={JSON.parse(getSessionCount)}
                            />
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>


            <div className="accordion tile sm-12 md-12 lg-12 xl-12">
              <div className="accordion-list" style={{ display: `flex`, flexFlow: `column` }}>
                <button
                  className="btn primary"
                  onClick={() => navigate("/beacon-monitoring/all_session")}
                  style={{ marginLeft: `auto`, marginBottom:`5px` }}
                >
                  View All Sessions
                </button>
                <div className={openPanic ? "accordion-item opened" : "accordion-item"}>
                  <div className="title" onClick={() => { setOpenPanic(!openPanic) }}>
                    <div className="header">
                      <div className="left">
                        <div className="title">Attention Required - Panic Alerts & Overrun Sessions ({opneOverrunCount}) &nbsp;
                          <span className="tooltip" role="tooltip" aria-describedby="tooltip1">
                            <i className='icon icon-info-solid'></i>
                            <span className="message top" id="tooltip1">These sessions require immediate action on <br />your part given a worker may have initiated a <br />panic alert or have been unable to interact <br />with their device to close session gracefully. <br />Use the Acknowledge button to indicate that a due <br />action as per your organization's processes <br />has been initiated or completed</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content">
                    <div className="column">
                      {loadingVal?.panicOverrun ? (
                        <Loading />
                      ) : (
                        <>
                          {errMsg.panicOverrun !== "Success" &&
                            errMsg.panicOverrun !==
                            "No data available in this date range" ? (
                            <AlertEDS
                              errMsg={errMsg.panicOverrun}
                              status_code={201}
                              close={false}
                            />
                          ) : (
                            <>
                              {!_.isEmpty(getPanicOverrunData) && (
                                <OpenAlertTable
                                  loneworkerData={JSON.parse(getPanicOverrunData)}
                                  callGetDeficiencies={callGetDeficiencies}
                                  setOpneOverrunCount={setOpneOverrunCount}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="accordion tile sm-12 md-12 lg-12 xl-12">
              <div className="accordion-list">
                <div className={openActiveTable ? "accordion-item opened" : "accordion-item"}>
                  <div className="title" onClick={() => { setOpenActiveTable(!openActiveTable) }}>
                    <div className="header">
                      <div className="left">
                        <div className="title">Active Sessions</div>
                      </div>
                    </div>
                  </div>
                  <div className="content">
                    <div className="column">
                      {loadingVal?.activeMissChecking ? (
                        <Loading />
                      ) : (
                        <>
                          {errMsg.activeMissChecking !== "Success" &&
                            errMsg.activeMissChecking !==
                            "No data available in this date range" ? (
                            <AlertEDS
                              errMsg={errMsg.activeMissChecking}
                              status_code={201}
                              close={false}
                            />
                          ) : (
                            <>
                              {!_.isEmpty(getActiveMissCheckingData) && (
                                <ActiveSessionTable
                                  loneworkerData={JSON.parse(getActiveMissCheckingData)}
                                  callGetDeficiencies={callGetDeficiencies}
                                />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="tile sm-12 md-12 lg-12 xl-12">
              <div className="header pb-0">
                <div className="left"></div>
                <div className="right">
                  <button
                    className="btn primary"
                    onClick={() => navigate("/beacon-monitoring/all_session")}
                    style={{ margin: 0 }}
                  >
                    View All Sessions
                  </button>
                </div>
              </div>
            </div> */}

            <div className="tile sm-12 md-12 lg-12 xl-12">
              <div className="header">
                <div className="left">
                  <div className="title">Real-time Location Tracker</div>
                </div>
              </div>
              <div className="content">
                <div className="column">
                  {loadingVal?.isMap ? (
                    <Loading />
                  ) : (
                    <>
                      {errMsg.loneWorkerData !== "Success" &&
                        errMsg.loneWorkerData !==
                        "No data available in this date range" ? (
                        <AlertEDS
                          errMsg={errMsg.loneWorkerData}
                          status_code={201}
                          close={false}
                        />
                      ) : (
                        <>
                          {!_.isEmpty(getMapData) ? (
                            <LoneWorkerMap
                              getMapData={getMapData}
                              loneworkerActiveData={getLoneData.usergroup_location}
                            />
                          )
                            :
                            (<div className="empty-state">
                              <div className="message">
                                <p>No results found for selected date.</p>
                              </div>
                            </div>)
                          }
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>

          </>
        )
        }
      </div >
    </>
  );
}
