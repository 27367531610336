import { GeographicalViewChart } from "@eds/vanilla";
import _ from "lodash";
import { useEffect, useState } from "react";
export default function LoneWorkerMap({
  getMapData,
  loneWorkerusergroup_location,
}) {
  const [getButton, setButton] = useState([]);
  const [activenew, setactivenew] = useState();
  const [getMapDraw, setMapDraw] = useState();
  const [getFilter, setFilter] = useState([]);
  // let ActiveCord = [];
  // let _filter = [];
  const parseGeoCoordinates = (geoCoordinatesStr) => {
    try {
      return JSON.parse(geoCoordinatesStr);
    } catch (error) {
      return null;
    }
  };
  const { geo_coordinates, zoomlevel } = loneWorkerusergroup_location || {};
  const parsedGeoCoordinates = parseGeoCoordinates(geo_coordinates);

  // const AlertData = (alert) => {
  //   let data =
  //     alert === "Overrun-Session"
  //       ? "Major"
  //       : alert === "Active-Session"
  //         ? "Minor"
  //         : alert === "Panic-Alert" && "Critical";
  //   return [{ name: data, value: alert }];
  // };

  const products = {
    alerts: [{ name: "Critical" }, { name: "Major" }, { name: "Minor" }, { name: "maintenance" }],
    nodes: "",
  };

  useEffect(() => {
    const mapElement = document.querySelector("#selection-map");
    const map = new GeographicalViewChart({
      element: mapElement,
      showScrollLock: true,
    });
    const initializeMap = () => {
      let node = [];
      let btn = [];

      getMapData.forEach((i, k) => {
        btn.push(i.status);
        // if (i.status === "Active-Session") {
        //   ActiveCord.push({
        //     x: i.x,
        //     y: i.y,
        //   });
        // }
        if (!getFilter.includes(i.status)) {
          node.push({
            key: k + 1,
            name: i.name,
            icon: i.icon,
            x: i.x,
            y: i.y,
            alerts: i.alerts,
            "maintenance": i.status === "Successful" ? true : false,
          });
        }

      });

      setButton([...new Set(btn)]);

      products.nodes = node;

      setactivenew(products);
      if (getMapDraw) {
        // console.log('getMapDraw', getMapDraw)
        const filterContainer = document.querySelector(".filterBtn");
        const activeButtons = Array.from(
          filterContainer.querySelectorAll(".switch.active span")
        ).map((spanElement) => spanElement.textContent);

        const filteredNodes = activenew.nodes.filter((node) => {
          const nodeAlerts = node.alerts.map((alert) => alert.value);
          return nodeAlerts.every((value) => activeButtons.includes(value));
        });
        const filteredData = {
          alerts: products.alerts || [],
          nodes: filteredNodes || [],
        };
        getMapDraw?.draw(filteredData);
      } else {
        if (!mapElement) {
          console.error("Map element not found");
          return;
        }

        map.init();
        map?.setView(
          parsedGeoCoordinates || [0, 0],
          zoomlevel ? 10 : 2
        );
        map.draw(products);

        setMapDraw(map);
      }
    };

    initializeMap();
    return () => {
      map.destroy();
    };

  }, []);

  const btnFilter = (e, status) => {
    e.target.parentNode.classList.toggle("active");
    let _filter = getFilter;
    let _node = [];

    if (getFilter.includes(status)) {
      _filter = getFilter.filter((i) => i !== status);
      setFilter(_filter);
    } else {
      _filter.push(status);
      setFilter(_filter);
    }

    getMapData.forEach((i, k) => {
      if (!_filter.includes(i.status)) {
        _node.push({
          key: k + 1,
          name: i.name,
          icon: i.icon,
          x: i.x,
          y: i.y,
          alerts: i.alerts,
          "maintenance": i.status === "Successful" ? true : false,
        });
      }
    });

    products.nodes = _node;

    getMapDraw?.setView(
      parsedGeoCoordinates || [0, 0],
      zoomlevel ? 10 : 2
    );

    getMapDraw.draw(products);
  };

  return (
    <div>
      <div className="filterBtn">
        {getButton?.map((i, k) => {
          return (
            <label
              className={
                i === "Current"
                  ? "switch mb-bs activeSession active"
                  : i === "Panic-Alert"
                    ? "switch mb-bs panicAlert active"
                    : i === "Overrun-Session"
                      ? "switch mb-bs overrunSession active"
                      : i === "Miss Check-In"
                        ? "switch mb-bs missCheckIn active"
                        : "switch mb-bs completedSession active"
              }
              key={k}
            >
              <input
                type="checkbox"
                onClick={(e) => btnFilter(e, i)}
                defaultChecked
              />
              <i className="ball"></i>
              <span>{i}</span>
            </label>
          );
        })}
      </div>
      <div id="selection-map"></div>
    </div>
  );
}
