import { Table } from "@eds/vanilla";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

export default function HazardIDTable({ getListHazardIdenti, callRefresh }) {
    const navigate = useNavigate();
    const [getData, setData] = useState();

    const _getDateTime = (date_time) => {
        let d = new Date(date_time),
            dformat = [d.getMonth() + 1,
            d.getDate(),
            d.getFullYear()].join('/') + ' ' +
                [d.getHours(),
                d.getMinutes(),
                d.getSeconds()].join(':');
        return dformat;
    }

    const handleTableData = () => {
        let products = [];
        getListHazardIdenti.map(i => {
            products.push({
                request_ID: i.id,
                request_date_time: _getDateTime(i.created),
                customer: i.customer,
                site_ID: i.site_id,
                site_survey_archive: i.archive.filename,
                status: i.status === "BATCH_COMPLETED" ? "Completed" : "In-progress",
                user: i.user_employee_id,
                actions: [i.id, i.status === "BATCH_COMPLETED" ? "Completed" : "In-progress"],
                hazards: i?.hazards,
            });
        })
        return products;
    };

    useEffect(() => {
        const tableDOM = document.querySelector("#HazardIDTable");
        if (_.isEmpty(getData)) {
            const table = new Table(tableDOM, {
                data: handleTableData(),
                columns: [
                    {
                        key: "request_ID",
                        title: "ID",
                        sort: "none",
                    },
                    {
                        key: "request_date_time",
                        title: "Date",
                        sort: "none",
                        cellClass: "text-wrap",
                    },
                    {
                        key: "customer",
                        title: "Customer",
                        sort: "none",
                    },
                    {
                        key: "site_ID",
                        title: "Site ID",
                        sort: "none",
                    },
                    {
                        key: "site_survey_archive",
                        title: "Survey Archive",
                        sort: "none",
                    },
                    {
                        key: "status",
                        title: "Status",
                        sort: "none",
                        onCreatedCell: (td, data) => {
                            td.innerHTML = `<kbd class="tag ${data === "Completed" ? "green" : "orange"}">${data}</kbd>`
                        },
                    },
                    {
                        key: "user",
                        title: "User",
                        sort: "none",
                    },
                    {
                        key: "actions",
                        title: "Actions",
                        sort: "none",
                        onCreatedCell: (td, data) => {
                            td.innerHTML = `<button class="btn primary icon-btn viewMore" ${data[1] !== "Completed" && 'disabled'}><i class="icon icon-eye"></i> View</button>`
                            td.querySelector("button.viewMore").addEventListener(
                                "click",
                                () => {
                                    navigate("/site-hazards/view-details", { state: { id: data[0] } })
                                }
                            );
                        },
                    }
                ],
                sortable: true,
                expandable: false,
                // onCreatedDetailsRow: (td, data) => {
                //     let hData = data?.hazards?.map((i) => `<div class="history">
                //     <div class="sub-History"><strong>Category: </strong> ${i.category.join(", ")}</div>
                //     <div class="sub-History"><strong>Hazard: </strong> ${i.hazard}</div>
                //     </div>`).join(" ")
                //     td.innerHTML = data.hazards === null ? "In Progress" : hData;
                // },

            });
            table.init();
            setData(table);
        } else {
            getData.update(handleTableData());
        }
    }, [getListHazardIdenti]);

    return (
        <>
            <div className="table-top">
                <div className="table-top-left">
                    <div className="table-info"></div>
                </div>
                <div className="table-top-right">
                    <div className="table-buttons">
                        <button className="btn" id="exportData" onClick={() => callRefresh()}>Refresh</button>
                    </div>
                    <div className="table-search">
                        <input type="text" className="with-icon" placeholder="Search for..." />
                        <div className="suffix icon-inside"><i className="icon icon-search"></i>
                        </div>
                    </div>
                </div>
            </div>
            <table id="HazardIDTable" className="table dashed compact stickyHeader horizonatalScroll"></table>
            <div className="table-bottom">
                <div className="pagination-group">
                    <ul className="pagination"></ul>
                    <div className="actions">
                        <label className="left">Go to</label>
                        <input type="number" min="1" />
                        <label className="left">Show</label>
                        <div className="select" data-type="single">
                            <button className="btn current-options">5</button>
                            <div className="options-list">
                                <div className="item active">5</div>
                                <div className="item">10</div>
                                <div className="item">15</div>
                                <div className="item">All</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}