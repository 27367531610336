import { Table } from "@eds/vanilla";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import LargeImgPopup from "./LargeImgPopup";

export default function ViewDetailTable({ getHazardIdenti }) {
    const [getData, setData] = useState();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [getImgDetail, setImgDetail] = useState({
        imgURL: "",
        imgName: "",
        hazard: "",
        category: "",
        description: ""
    });

    const handleViewMore = (data) => {
        const fileName = data.target.getAttribute("file-name");
        const imgURL = getHazardIdenti.archive.files.map(i => i.id === fileName && i.url).filter(j => j)[0];
        let hazard;
        let category;
        let description;

        getHazardIdenti.hazards.map(i => {
            i.images.map(j => {
                if (j === fileName) {
                    hazard = i.hazard;
                    category = i.category.map(cat => cat).join(", ");
                }
            })
        });

        getHazardIdenti.result.output.map(i => {
            if (i.custom_id === fileName) {
                description = i.message_content.hazard_description.join(", ")
            }
        })
        setImgDetail({ imgURL: imgURL, imgName: fileName, hazard: hazard, category: category, description: description });
        setIsDialogOpen(true);
    }

    const _getImgName = (imgName) => {
        let fileName = [];
        imgName.map(i => {
            getHazardIdenti.archive.files.map(j => {
                if (j.id === i) {
                    fileName.push(j.url.split('?')[0].split('/').pop())
                }
            })
        });
        return [imgName, fileName];
    }

    const handleTableData = () => {
        let products = [];
        getHazardIdenti.hazards.map(i => {
            products.push({
                category: i.category.map(i => i).join(", "),
                hazard: i.hazard,
                images: _getImgName(i.images),
            });
        })
        return products;
    };

    useEffect(() => {
        const tableDOM = document.querySelector("#ViewDetailTable");
        if (_.isEmpty(getData)) {
            const table = new Table(tableDOM, {
                data: handleTableData(),
                columns: [
                    {
                        key: "category",
                        title: "Category",
                        sort: "none",
                    },
                    {
                        key: "hazard",
                        title: "Hazard",
                        sort: "none",
                        cellClass: "text-wrap",
                    },
                    {
                        key: "images",
                        title: "Images",
                        onCreatedCell: (td, data) => {
                            let hData = data[0].map((i, k) => `<span class="viewLink"><a class="viewMore" file-name=${i}>${data[1][k]}</a></span>`).join("");
                            td.innerHTML = hData;
                        },
                    },
                ],
                sortable: true,
                expandable: false,
            });
            table.init();
            setData(table);
            document.addEventListener("click", function (e) {
                const target = e.target.closest("a.viewMore"); // Or any other selector.
                if (target) {
                    handleViewMore(e);
                }
            });
        } else {
            getData.update(handleTableData());
        }
    }, [getHazardIdenti]);

    return (
        <>
            {isDialogOpen &&
                <LargeImgPopup
                    setIsDialogOpen={setIsDialogOpen}
                    getImgDetail={getImgDetail}
                    getHazardIdenti={getHazardIdenti}
                />
            }
            <div className="row">
                <div className="tile sm-12 md-12 lg-12 xl-12">
                    <div className="header">
                        <div className="left">
                            <div className="title">Hazards</div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="column">
                            <div className="table-top">
                                <div className="table-top-left">
                                    <div className="table-info"></div>
                                </div>
                                <div className="table-top-right">
                                    <div className="table-search">
                                        <input type="text" className="with-icon" placeholder="Search for..." />
                                        <div className="suffix icon-inside"><i className="icon icon-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table id="ViewDetailTable" className="table dashed "></table>
                            <div className="table-bottom">
                                <div className="pagination-group">
                                    <ul className="pagination"></ul>
                                    <div className="actions">
                                        <label className="left">Go to</label>
                                        <input type="number" min="1" />
                                        <label className="left">Show</label>
                                        <div className="select" data-type="single">
                                            <button className="btn current-options">5</button>
                                            <div className="options-list">
                                                <div className="item active">5</div>
                                                <div className="item">10</div>
                                                <div className="item">15</div>
                                                <div className="item">All</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}