import { useEffect, useState, useRef } from "react";
import { Dialog } from "@eds/vanilla";
import { AddSupplier, updateSupplier } from "../../api/Validomain.api";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";
import EDSCustomMultiselect from "../../components/EDSCustomMultiselect/EDSCustomMultiselect";
import _ from "lodash";

export default function AddUpdateSupplier({
  addnewsupplier,
  setaddnewsupplier,
  setconfirmationMessage,
  confirmationMessage,
  setLoading,
  setErrMsg,
  headersList,
  ValidsupplierList,
  loadingVal,
  distributionData,
  getAdministrators,
  setAdministrators,
  getRecipients,
  setRecipients
}) {
  const domainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
  // /^([a-zA-Z][a-zA-Z0-9-]*\.)+[a-zA-Z]{2,}$/;

  const [formData, setFormData] = useState({
    domainName: "",
    supplier: "",
    supplier_id: "",
    allValid: false,
    verified: false
  });
  const [isAddDisabled, setIsAddDisabled] = useState(true);
  const [getErr, setErr] = useState("");

  useEffect(() => {
    if (addnewsupplier.type === "Edit" && !_.isEmpty(addnewsupplier.data)) {
      setFormData({
        domainName: addnewsupplier.data.allowed_domain,
        supplier: addnewsupplier.data.supplier_name,
        supplier_id: addnewsupplier.data.supplier_id,
        allValid: true,
        verified:addnewsupplier.data.verified
      });
      setIsAddDisabled(false);
    }

    const dialogDOM = document.getElementById("addnewUser-dialog");
    const dialog = new Dialog(dialogDOM);
    dialog.init();
    dialog.show();
    return () => {
      dialog.destroy();
    };
  }, []);

  // const inputMultiSelectOptionsChange = (e) => {
  //   // console.log(e.target.value)

  // }

  // const inputMultiSelectQuerySelectorClick = (e) => {
  //   // console.log(e.target.value)
  // }

  const onCancel = () => {
    setaddnewsupplier({ ...addnewsupplier, isOpen: false });
    setErr("");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData };
    if (name === "domainName") {
      const namesArray = value.split(",").map((val) => val.trim());
      const validationResult = namesArray.map((val) => domainRegex.test(val));
      updatedFormData[name] = namesArray;
      updatedFormData.allValid = validationResult.every((result) => result);
      if (updatedFormData.allValid) {
        e.target.classList.remove("invalid");
      } else {
        e.target.classList.add("invalid");
      }
    }
    else if (name === "verified") {
      updatedFormData[name] = value === "true" ? true : false;
    }
    else {
      updatedFormData[name] = value;
    }
    setFormData(updatedFormData);
    setIsAddDisabled(!(updatedFormData.supplier && updatedFormData.domainName && updatedFormData.allValid))
  };

  const handleAdd = async () => {
    const newData = { ...formData };
    newData.supplier_administrators = getAdministrators;
    newData.notification_recipients = getRecipients;
    delete newData.allValid;
    if (addnewsupplier.type === "Add") {
      await AddSupplier(
        setconfirmationMessage,
        newData,
        setLoading,
        setErr,
        headersList
      );
    } else {
      newData.internal_supplier_id = addnewsupplier.data.internal_supplier_id;
      await updateSupplier(
        setconfirmationMessage,
        newData,
        setLoading,
        setErrMsg,
        headersList
      );
    }
    ValidsupplierList({ formData, getAdministrators, getRecipients });
  };

  useEffect(() => {
    if (getErr === "" && confirmationMessage.statusCode !== "") {
      setTimeout(() => {
        onCancel();
        setconfirmationMessage({ errorMsg: "", statusCode: "" });
      }, 2000);
    }
  }, [getErr, confirmationMessage]);

  return (
    <div id="addnewUser-dialog" className="dialog md show" data-type="simple">
      <div className="content ">
        <div className="top">
          <div className="title">{addnewsupplier.type === "Add" ? "Add New Contractor" : "Update Contractor"}</div>
          <div className="right">
            <button className="btn-icon" onClick={onCancel}>
              <i className="icon icon-cross"></i>
            </button>
          </div>
        </div>

        {loadingVal ? (
          <Loading />
        ) : confirmationMessage.errorMsg !== "" ? (
          <div className="tile sm-12">
            <AlertEDS
              errMsg={confirmationMessage.errorMsg}
              status_code={
                confirmationMessage.statusCode === 200 ? 200 : "error"
              }
              close={false}
            />
          </div>
        ) : (
          <>
            <div className="eds-form ">
              <div className="row form-body mb-0">
                <div className="column sm-12 md-12 lg-6">
                  <div className="form-group">

                    <div className="form-element">
                      <label htmlFor="supplier" className="required">
                      Contractor
                      </label>

                      <input
                        type="text"
                        name="supplier"
                        placeholder="Enter Contractor Name"
                        value={formData.supplier}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-element">
                      <label htmlFor="domainName" className="required">
                        Allow Domains
                      </label>
                      <input
                        type="text"
                        name="domainName"
                        className="validation"
                        placeholder="Enter Domain Name"
                        value={formData.domainName}
                        onChange={handleChange}
                      />
                      <span className="validation-msg error-message-required">
                        Please enter an valid input
                      </span>
                    </div>


                    <div className="form-element">
                      <label htmlFor="supplier_id">Contractor ID</label>
                      <input
                        style={{ width: "100%" }}
                        type="number"
                        min="0"
                        name="supplier_id"
                        placeholder="Enter Contractor ID"
                        value={formData.supplier_id}
                        onChange={handleChange}
                      ></input>
                    </div>
                  </div>
                </div>
                <div className="column sm-12 md-12 lg-6" style={{ borderRight: 0 }}>
                  <div className="form-group">
                    <div className="form-element">
                      <label htmlFor="supplier_administrators">Contractor Representative</label>
                      <EDSCustomMultiselect
                        setStatusOption={setAdministrators}
                        formDetailsUG={distributionData.getCCList}
                        defaultValues={getAdministrators}
                        filterName="adminUserValue"
                        dataType="input-multi"
                        disabled={false}
                        selectRef={[]}
                      />
                    </div>
                    <div className="form-element">
                      <label htmlFor="notification_recipients">Notification Recipients</label>

                      <EDSCustomMultiselect
                        setStatusOption={setRecipients}
                        formDetailsUG={distributionData.getCCList}
                        defaultValues={getRecipients}
                        filterName="recipentlistValue"
                        dataType="input-multi"
                        disabled={false}
                        selectRef={[]}
                      />
                    </div>

                    <div className="form-element verified">
                      <label>Verification Status</label>
                      <div>
                        <input id="rbtn1" name="verified" value={true} type="radio" onChange={(event) => handleChange(event)} checked={formData.verified === true} />
                        <label htmlFor="rbtn1">Verified</label>
                        <input id="rbtn2" name="verified" value={false} type="radio" onChange={(event) => handleChange(event)} checked={formData.verified === false} />
                        <label htmlFor="rbtn2">Unverified</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="message color-red"><br />{getErr}</div>
            </div>
            <div className="bottom">
              <button className="btn" onClick={onCancel}>
                Cancel
              </button>
              <button
                className="btn primary"
                onClick={handleAdd}
                disabled={isAddDisabled}
              >
                {addnewsupplier.type === "Add" ? "Add" : "Update"}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
