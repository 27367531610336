import { Table } from "@eds/vanilla";
import React, { useEffect, useState, useRef } from "react";
import { exportToCSVMethod } from "../../utils/helperMethod";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getLoneWorkerAcknowledge } from "../../api/loneWorker.api";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import Loading from "../../components/Loading/Loading";

export default function AllSessionTable({
    loneworkerData,
    callGetDeficiencies,
    // handleSettings,
    // handleManagerList,
}) {

    const [getData, setData] = useState();
    const [exportData, setExportData] = useState([]);
    const [loadingVal, setLoading] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    // const rolesMap = useSelector((state) => state.userAccess.roles);
    const [noSession, SetSession] = useState(false);
    const { headers } = useSelector((state) => state.envVariables);

    const progressData = (startTime, endTime, timer) => {
        let start_date = new Date(startTime).getTime() / (1000 * 60);
        let end_date = new Date(endTime).getTime() / (1000 * 60);
        let currDate = new Date().getTime() / (1000 * 60);

        let totalTime = end_date - start_date; // / 1000;
        let diff;

        if (currDate > end_date) {
            diff = end_date - start_date;
        } else {
            diff = currDate - start_date;
        }

        let finalTime = parseInt((diff / totalTime) * 100);

        const startDate = new Date(startTime);
        const endDate = new Date(endTime);
        const seconds = (endDate.getTime() - startDate.getTime()) / 1000;
        const totalMinutes = Math.floor(seconds / 60);

        const sec = seconds % 60;
        const rhours = Math.floor(totalMinutes / 60);
        const fMin = totalMinutes % 60;

        let finalData = [
            finalTime > 100 ? 100 : finalTime,
            rhours + "h : " + fMin + "m : " + sec + "s",
        ];

        return finalData;
    };

    const alertData = (email, Notification, status, sms) => {
        let _email =
            status === "Panic-Alert" || status === "Overrun-Session"
                ? `<span class="iconDiv ml-sm mr-sm"><i class="icon icon-email color-red"></i></span>`
                : ``;
        let _Notification =
            status === "Panic-Alert" || status === "Overrun-Session"
                ? `<span class="iconDiv ml-sm mr-sm"><i class="icon icon-notification color-red"></i></span>`
                : ``;
        let _sms =
            sms === "Y" && (status === "Panic-Alert" || status === "Overrun-Session")
                ? `<span class="iconDiv ml-sm mr-sm"><i class="icon icon-message-contact-us color-red"></i></span>`
                : ``;

        return [_email + _Notification + _sms];
    };

    const handleAcknowledge = async (sessionId) => {
        await getLoneWorkerAcknowledge(setLoading, setErrMsg, sessionId, closeAlert, headers);
    }

    useEffect(() => {
        if (_.isEmpty(loneworkerData["Email"])) {
            SetSession(true);
        } else {
            SetSession(false);
        }
    }, [loneworkerData]);

    const _data = () => {
        let products = [];
        for (
            let tblData = Object.keys(loneworkerData["Email"]).length - 1;
            tblData >= 0;
            tblData--
        ) {
            products.push({
                "User Name": [
                    loneworkerData["Fullname"][tblData],
                    loneworkerData["Status"][tblData],
                ],
                "Email ID": loneworkerData["Email"][tblData],
                "Start Date/Time": loneworkerData["Session Start Time"][tblData],
                "Timer in-Min": loneworkerData["Timer"][tblData],
                Progress: loneworkerData["Status"][tblData],
                Status: loneworkerData["Status"][tblData],
                "Extended Time": loneworkerData["Extended Timer"][tblData],
                Alert: alertData(
                    loneworkerData["Alertemail"][tblData],
                    loneworkerData["AlertPushnotification"][tblData],
                    loneworkerData["Status"][tblData],
                    loneworkerData["Alertsms"][tblData]
                ),
                Updates:
                    loneworkerData["Updates"][tblData] === ""
                        ? ["Not Available"]
                        : loneworkerData["Updates"][tblData],
                ProgressData: progressData(
                    loneworkerData["Session Start Time"][tblData],
                    loneworkerData["Session End Time"][tblData],
                    loneworkerData["Timer"][tblData]
                ),
                Acknowledgement: [loneworkerData["Acknowledge"][tblData], loneworkerData["Status"][tblData], loneworkerData["SessionId"][tblData]],
            });
        }
        setExportData(products);
        return products;
    };

    useEffect(() => {
        if (!_.isEmpty(loneworkerData)) {
            const tableDOM = document.querySelector("#LoneWorkerTable");
            if (_.isEmpty(getData)) {
                const table = new Table(tableDOM, {
                    data: _data(),
                    columns: [
                        {
                            key: "User Name",
                            title: "User Name",
                            sort: "none",
                            onCreatedCell: (td, data) => {
                                td.innerHTML = `<span class="alertSpace">
                                ${data[1] === "Active-Session" ? `<i class="icon icon-alarm-level4 color-blue"></i>` :
                                        data[1] === "Miss Check-In" ? `<i class="icon icon-alarm-level4 color-yellow"></i>` :
                                            data[1] === "Overrun-Session" ? `<i class="icon icon-alarm-level4 color-orange"></i>` :
                                                data[1] === "Completed-Session" ? `<i class="icon icon-alarm-level4 color-green"></i>` :
                                                    `<i class="icon icon-alarm-level4 color-red"></i>`
                                    }</span> ${data[0]}`;
                            },
                        },
                        {
                            key: "Start Date/Time",
                            title: "Start Date/Time",
                            sort: "none",
                        },
                        {
                            key: "Timer in-Min",
                            title: "Timer (mins)",
                            cellStyle: "text-align: center",
                        },
                        {
                            key: "ProgressData",
                            title: "Progress",
                            sort: "none",
                            cellClass: "progressTD",
                            onCreatedCell: (td, data) => {
                                td.innerHTML = `<div class="progress-bar thin ${data[0] === 100
                                    ? "completeProgress"
                                    : data[0] > 80
                                        ? "eightyComplete red"
                                        : data[0] > 60
                                            ? "sixtyComplete"
                                            : "inProcess"
                                    }"><progress value=${data[0]} max="100"></progress><span>${data[1]
                                    }</span></div>`;
                            },
                        },
                        {
                            key: "Status",
                            title: "Status",
                            sort: "none",
                            cellStyle: "text-align: center",
                            onCreatedCell: (td, data) => {
                                td.innerHTML = `<kbd class="tag ${data === "Completed-Session" ? "green" : data === "Panic-Alert" ? "red" : data === "Overrun-Session" ? "orange" : data === "Active-Session" ? "blue" : "yellow"}">${data === "Completed-Session" ? "Successful" : data === "Miss Check-In" ? "Missed Check-In" : data}</span>`;
                            },
                        },
                        {
                            key: "Extended Time",
                            title: "Extended Time",
                            cellStyle: "text-align: center",
                        },
                        {
                            key: "Alert",
                            title: "Alert",
                            cellClass: "progressTD",
                            cellStyle: "text-align: center",
                            onCreatedCell: (td, data) => {
                                td.innerHTML = data;
                            },
                        },
                        {
                            key: "Acknowledgement",
                            title: "Action",
                            sort: "none",
                            cellStyle: "text-align: center",
                            onCreatedCell: (td, data) => {
                                td.innerHTML = data[0] === "N" && (data[1] === "Panic-Alert" || data[1] === "Overrun-Session")
                                    ? '<button class="btn primary getAck" >Acknowledge</button>'
                                    : '';
                                data[0] === "N" && (data[1] === "Panic-Alert" || data[1] === "Overrun-Session") &&
                                    td.querySelector("button.getAck")
                                        .addEventListener("click", () => {
                                            handleAcknowledge(data[2]);
                                        });
                            },
                        },
                    ],
                    sortable: true,
                    expandable: true,
                    onCreatedDetailsRow: (td, data) => {
                        let hData = data.Updates.map(
                            (i) =>
                                `<div class="history"><div class="sub-History">${i}</div></div>`
                        ).join("");
                        td.innerHTML = hData;
                    },
                });
                table.init();
                setData(table);
            } else {
                //setData(_data());
                getData.update(_data());
            }
        }
    }, [loneworkerData]);

    useEffect(() => {
        let callCheck = 1;
        const interval = setInterval(async () => {
            if (document.querySelector('#LoneWorkerTable .expanded') === null) {
                await callGetDeficiencies(1);
            }
            else {
                callCheck++;
                // Below 4 means (4 * 15000 time)
                if (callCheck > 8) {
                    await callGetDeficiencies(1);
                }
            }
        }, 15000);

        return () => clearInterval(interval);
    }, [loneworkerData]);



    const callExport = () => {
        debugger
        const csvData = [];
        exportData?.forEach((val) => {
            csvData.push({
                "User Name": val?.["User Name"]?.[0],
                "Start Date/Time": val?.["Start Date/Time"],
                "Timer in-Min": val?.["Timer in-Min"],
                Progress: val?.["ProgressData"][1],
                Status: val?.Status === "Completed-Session" ? "Successful" : val?.Status,
                "Extended Time": val?.["Extended Time"],
            });
        });
        exportToCSVMethod(csvData, "All Sessions");
    };

    useEffect(() => {
        let _tr = document.querySelector(".stickyHeader thead tr");
        let _top = document.querySelector(".filterDiv").offsetHeight;
        if (_tr) {
            _tr.style.top = _top + "px";
        }
    }, [loneworkerData]);

    const closeAlert = () => {
        setErrMsg("");
    }

    return (
        <>
            {loadingVal && (<div className="SiderBarLoader"><Loading /></div>)}
            {errMsg !== "" && (
                <AlertEDS
                    errMsg={errMsg}
                    status_code={errMsg.includes("successfully") ? 200 : 201}
                    close={true}
                    closeAlert={closeAlert}
                />
            )}
            {!noSession ? (
                <>
                    <div className="table-top">
                        <div className="table-top-left">
                            <div className="table-info"></div>
                        </div>
                        <div className="table-top-right">
                            <div className="table-buttons">
                                <button
                                    className="btn"
                                    id="exportData"
                                    onClick={() => callExport()}
                                    disabled={_.isEmpty(exportData)}
                                >
                                    Export
                                </button>
                                <button className="btn" onClick={() => callGetDeficiencies(0, "All")}>
                                    Refresh
                                </button>
                            </div>
                            <div className="table-search">
                                <input
                                    type="text"
                                    className="with-icon"
                                    placeholder="Search for..."
                                />
                                <div className="suffix icon-inside">
                                    <i className="icon icon-search"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table
                        id="LoneWorkerTable"
                        className="table dashed stickyHeader horizonatalScroll"
                        style={{ tableLayout: `auto` }}
                    ></table>
                    <div className="table-bottom">
                        <div className="pagination-group">
                            <ul className="pagination"></ul>

                            <div className="actions">
                                <label className="left">Go to</label>
                                <input type="number" min="1" />

                                <label className="left">Show</label>
                                <div className="select" data-type="single">
                                    <button className="btn current-options">5</button>

                                    <div className="options-list">
                                        <div className="item active">5</div>
                                        <div className="item">10</div>
                                        <div className="item">15</div>
                                        <div className="item">All</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )
                :
                (<div className="empty-state">
                    <div className="message">
                        <p>No results found for selected date.</p>
                    </div>
                </div>)
            }
        </>
    );
}
