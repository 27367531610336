import AlertEDS from "../../../components/AlertWarn/AlertEDS";
import { Table } from "@eds/vanilla";
import { useEffect, useState } from "react";
import {
    getLoneWorkerAddSenderList,
    getLoneWorkerRemoveSenderList,
} from "../../../api/loneWorker.api";
import Loading from "../../../components/Loading/Loading";
import { useSelector } from "react-redux";
import { getLoneWorkerSenderList } from "../../../api/loneWorker.api";
import _ from "lodash";
import AddPanicRecipientPopup from "./AddPanicRecipientPopup";
export default function BeaconPanicList({ getLoneWorkerModalShow, setLoneWorkerModalShow }) {
    const [getLoneList, setLoneList] = useState([]);
    const [getCountryRef, setCountryRef] = useState(false);
    const [getLoading, setLoading] = useState(false);
    const [getResponce, setResponce] = useState({
        status_code: "",
        message: "",
    });
    const [getError, setError] = useState({
        emailID: "",
        phoneNo: "",
        loneWorkerList: "",
    });
    const [getInput, setInput] = useState({
        emailID: "",
        countryCode: "",
        phoneNo: "",
    });
    const [getData, setData] = useState();
    const { headers } = useSelector((state) => state.envVariables);

    const getLoneWorkerSenderListData = () => {
        getLoneWorkerSenderList(setLoading, setLoneList, setErrMsgHandler, headers);
    }

    useEffect(() => {
        getLoneWorkerSenderListData();
    }, []);

    const setErrMsgHandler = (val) => {
        setError((prevState) => ({
            ...prevState,
            [Object.keys(val)[0]]: Object.values(val)[0],
        }));
    };

    const setResponceHandler = (val) => {
        setResponce((prevState) => ({
            ...prevState,
            [Object.keys(val)[0]]: Object.values(val)[0],
            [Object.keys(val)[1]]: Object.values(val)[1],
        }));
    };

    const handleDelete = async (evt) => {
        const mail = evt.target.closest("button").getAttribute("data-email");
        const phone = evt.target.closest("button").getAttribute("data-phone");
        let param = {
            mail: mail,
            number: phone === "undefined" ? "" : phone,
            // usergroup: UserGroupId
        };
        await getLoneWorkerRemoveSenderList(
            setLoading,
            param,
            setResponceHandler,
            closeAlert,
            headers
        );
        getLoneWorkerSenderListData();
    };

    const closeAlert = () => {
        setTimeout(() => {
            setResponce({ status_code: "", message: "" });
        }, 4000);
    };

    const _data = () => {
        let products = [];
        // Object.values(getLoneList['emailsId']).filter((i, k) => {
        for (let k = Object.keys(getLoneList["email"]).length - 1; k >= 0; k--) {
            products.push({
                Email_Id: getLoneList["email"][k],
                Phone_no: getLoneList["number"][k],
            });
        }
        return products;
    }

    useEffect(() => {
        if (!_.isEmpty(getLoneList)) {
            const tableDOM = document.querySelector("#actions-example");
            if (_.isEmpty(getData)) {
                const table = new Table(tableDOM, {
                    data: _data(),
                    columns: [
                        {
                            key: "Email_Id",
                            title: "Email ID",
                        },
                        {
                            key: "Phone_no",
                            title: "Phone No.",
                        },
                        {
                            key: "App_Notification",
                            title: "App Notification",
                            cellClass: "App_Notification",
                            onCreatedCell: (td, data) => {
                                td.innerHTML = `<input type="checkbox" checked disabled id="actions-example-master"><label class="checkbox select-row" for="actions-example-master"></label>`;
                            },
                        },
                    ],
                    maxHeight: "300px",
                    actions: true,
                    onCreatedActionsCell: (td, data) => {
                        td.innerHTML = `<button class="btn-icon delete" data-email=${data.Email_Id
                            } data-phone=${data.Phone_no === "" ? "" : data.Phone_no
                            }><i class="icon icon-trashcan"></i></button>`;
                        td.querySelector("button.delete").addEventListener("click", (evt) => {
                            handleDelete(evt);
                        });
                    },
                });
                table.init();
                setData(table);
            } else {
                getData.update(_data());
            }
        }
    }, [getLoneList]);

    return (
        <div className="body">
            {getError.loneWorkerList !== "Success" && getError.loneWorkerList !== "" ? (
                <AlertEDS errMsg={getError.loneWorkerList} status_code="error" close={false} />
            ) : (
                <>
                    {getLoneWorkerModalShow &&
                        <AddPanicRecipientPopup
                            setLoneWorkerModalShow={setLoneWorkerModalShow}
                            getCountryRef={getCountryRef}
                            setCountryRef={setCountryRef}
                            getResponce={getResponce}
                            setResponce={setResponce}
                            getError={getError}
                            setError={setError}
                            getInput={getInput}
                            setInput={setInput}
                            setLoading={setLoading}
                            getLoneWorkerSenderListData={getLoneWorkerSenderListData}
                        />
                    }
                    {getLoading && (
                        <Loading />
                    )}
                    {getResponce.status_code !== "" && (
                        <AlertEDS
                            errMsg={getResponce.message}
                            status_code={getResponce.status_code}
                            close={false}
                        />
                    )}
                    {!_.isEmpty(getLoneList) && (
                        <div className="tile">
                            <div className="table-top">
                                <div className="table-top-left">
                                    <div className="table-info"></div>
                                </div>
                                <div className="table-top-right">
                                    <div className="table-search">
                                        <input
                                            type="text"
                                            className="with-icon"
                                            placeholder="Search for..."
                                        />
                                        <div className="suffix icon-inside">
                                            <i className="icon icon-search"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <table id="actions-example" className="table dashed"></table>
                            <div className="table-bottom">
                                <div className="pagination-group">
                                    <ul className="pagination"></ul>

                                    <div className="actions">
                                        <label className="left">Go to</label>
                                        <input type="number" min="1" />

                                        <label className="left">Show</label>
                                        <div className="select" data-type="single">
                                            <button className="btn current-options">5</button>

                                            <div className="options-list">
                                                <div className="item active">5</div>
                                                <div className="item">10</div>
                                                <div className="item">15</div>
                                                <div className="item">All</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}