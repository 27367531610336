import { useState, useEffect } from "react";
import { Dialog } from "@eds/vanilla";
import Loading from "../Loading/Loading";
export default function ModelReportPreview({
  Childreportdata,
  setpreviewpopup,
  setChildreportdata,
  setShowSubReportModal,
}) {
  useEffect(() => {
    const dialogs = document.getElementById("PreviewDownload");
    if (dialogs) {
      Array.from(dialogs).forEach((dialogDOM) => {
        const dialog = new Dialog(dialogDOM);
        dialog.init();
      });
    }
  }, []);

  const onCloseHandler = () => {
    setShowSubReportModal(false);
    setpreviewpopup({ isOpen: false });
    setChildreportdata("");
  };

  return (
    <div
      id="PreviewDownload"
      className="dialog show fullscreen"
      data-trigger="#open-modalList"
      data-type="fullscreen"
    >
      <div className="content">
        <div className="top">
          <div
            className="title"
            style={{ fontWeight: "bold", fontFamily: "Ericsson Hilda" }}
          >
            Review
          </div>
          <div className="right">
            <button
              className="btn-icon"
              data-close="true"
              onClick={() => onCloseHandler()}
            >
              <i className="icon icon-cross"></i>
            </button>
          </div>
        </div>
        {Childreportdata !== "" ? (
          <>
            <div
              className="title"
              style={{
                fontWeight: "bold",
                marginTop: "10px",
              }}
            >
              Report Preview
            </div>
            <div className="body">
              <div style={{
                  width: "86vw",
                  height: "420px",
                  overflow: "hidden",
                  marginTop: "-20px",
                }}>
                <iframe
                  title="Report Content"
                  sandbox="allow-same-origin allow-scripts allow-downloads allow-popups"
                  srcDoc={Childreportdata}
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#fafafa",
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="body">
            <Loading width={"28"} height={"28"} />
          </div>
        )}
      </div>
    </div>
  );
}
