import { Dialog } from "@eds/vanilla";
import { useEffect, useState } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Loading from "../../components/Loading/Loading";

export default function LargeImgPopup({ setIsDialogOpen, getImgDetail, getHazardIdenti }) {
    const [getLoading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true);
        const dialogs = document.getElementById("AIImagePreview");
        if (dialogs) {
            Array.from(dialogs).forEach((dialogDOM) => {
                const dialog = new Dialog(dialogDOM);
                dialog.init();
            });
        }
        setTimeout(() => setLoading(false), 500);
    }, []);

    const closeHandler = () => {
        setIsDialogOpen(false);
    };

    return (
        <div
            id="AIImagePreview"
            className="dialog show fullscreen hazardsLargImg"
            data-trigger="#open-modalList"
            data-type="fullscreen"
        >
            <div className="content">
                <div className="top">
                    <div className="title">{getImgDetail?.imgURL?.split('?')[0].split('/').pop()}</div>
                    <div className="right">
                        <button className="btn-icon ">
                            <i className="icon icon-cross" onClick={closeHandler}></i>
                        </button>
                    </div>
                </div>
                <div className="body" style={{ marginTop: "1px" }}>
                    <div className="row" style={{ position: `relative` }}>

                        <div className="static">
                            <div className="dataInfo">
                                <div className="dataLabel">Hazard Category: </div>
                                <div className="info">{getImgDetail.category}</div>
                            </div>

                            <div className="dataInfo">
                                <div className="dataLabel">Hazard: </div>
                                <div className="info">{getImgDetail.hazard}</div>
                            </div>

                            <div className="dataInfo">
                                <div className="dataLabel">Description:</div>
                                <div className="info">{getImgDetail.description}</div>
                            </div>

                        </div>
                        {getLoading && <div className="hoverLoader"><Loading /></div>}
                        <LazyLoadImage
                            alt="Hazard Identification"
                            effect="blur"
                            wrapperProps={{
                                // If you need to, you can tweak the effect transition using the wrapper style.
                                style: { transitionDelay: "0.30s" },
                            }}
                            src={getImgDetail.imgURL} />


                    </div>


                </div>
            </div>
        </div>
    )
}