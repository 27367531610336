import React, { useEffect, useState } from "react";
import _ from "lodash";
import { getcreateHazardIdentification } from "../../api/HazardID.api";
import ViewDetailTable from "./ViewDetailTable";
import Loading from "../../components/Loading/Loading";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, Link } from "react-router-dom";
import AlertEDS from "../../components/AlertWarn/AlertEDS";
import { Breadcrumb, TabGroup } from "@eds/vanilla";
import { Dropdown } from '@eds/vanilla';
import OverView from "./OverView";

export default function ViewDetails() {
    const { pathname, state } = useLocation();
    const navigate = useNavigate();
    const [getHazardIdenti, setHazardIdenti] = useState([]);
    const [isLoading, setIsLoading] = useState({
        getHazardIdentification: false,
    });
    const [errMsg, setError] = useState({
        getHazardIdentification: "",
    });
    const { headersList } = useSelector((state) => state.envVariables);

    useEffect(() => {
        const request_id = state.id;
        getcreateHazardIdentification(headersList, setIsLoading, setError, request_id, setHazardIdenti);
    }, []);

    useEffect(() => {
        const dropdowns = document.querySelector('.dropdown');
        const dropdown = new Dropdown(dropdowns);
        dropdown.init();

        const tabs = document.querySelector('.tabs');
        const tabGroup = new TabGroup(tabs);
        tabGroup.init();

        return () => {
            tabGroup.destroy();
            dropdown.destroy();
        }
    }, []);

    useEffect(() => {
        const element = document.getElementById("config-breadcrumb-action");
        if (element) {
            const breadcrumb = new Breadcrumb(element);
            breadcrumb.init([
                {
                    title: "Site Hazards",
                    action: () => {
                        navigate("/site-hazards");
                    },
                },
                {
                    title: "View Details",
                },
            ]);
            return () => {
                breadcrumb.destroy();
            };
        }
    }, [pathname]);


    return (
        <>
            <div className="row HazardIDViewDetails">
                <div>
                    <div className="breadcrumb m-lg" id="config-breadcrumb-action"></div>
                </div>

                <div className="tile sm-12 md-12 lg-12 xl-12">
                    <div className="header">
                        <div className="left">
                            <div className="title"><i className='icon icon-versions'></i> {state.id} <kbd className="tag green">Completed</kbd></div>
                        </div>
                    </div>
                    <div className="content">
                        <div className="column">
                            <div className="tabs left">
                                <div className="titles">
                                    <div className="title selected" tabIndex="0">Overview</div>
                                    <div className="title" tabIndex="1">Hazards</div>
                                    <div className="title" tabIndex="2">Mitigations</div>
                                </div>

                                <div className="actionInfo">
                                    <div className="dropdown" data-type="click">
                                        <button className="btn clickable">Actions</button>
                                        <ul className="menu">
                                            <li className={getHazardIdenti?.excel_url !== undefined ? "item" : "item disabled"}><Link target="_blank" to={getHazardIdenti?.excel_url}>Download RAW data</Link></li>
                                            <li className="item disabled">Archive</li>
                                        </ul>
                                    </div>
                                    <Link target="_blank" className="btn primary" to={getHazardIdenti?.pdf_url}><i className='icon icon-download-save'></i> Download PDF</Link>
                                </div>

                                <div className="contents">
                                    <div className="content selected">
                                        {isLoading?.getHazardIdentification ? (
                                            <Loading />
                                        ) : (
                                            <>
                                                {errMsg.getHazardIdentification !== "Success" ? (
                                                    <AlertEDS
                                                        errMsg={errMsg.getHazardIdentification}
                                                        status_code={201}
                                                        close={false} />
                                                ) : (<>
                                                    {!_.isEmpty(getHazardIdenti) && (
                                                        <OverView getHazardIdenti={getHazardIdenti} state={state} />
                                                    )}
                                                </>)
                                                }
                                            </>
                                        )}
                                    </div>

                                    <div className="content">
                                        {!_.isEmpty(getHazardIdenti) && (
                                            <ViewDetailTable
                                                getHazardIdenti={getHazardIdenti}
                                            />
                                        )}
                                    </div>

                                    <div className="content">
                                        <div className="sm-12 md-12 lg-12 xl-12">
                                            <div className="header">
                                                <div className="left">
                                                    <div className="title">Mitigations</div>
                                                </div>
                                            </div>
                                            <div className="content">
                                                {!_.isEmpty(getHazardIdenti) && (
                                                    <div className="column">
                                                        <p>{getHazardIdenti?.summarized_data?.final_mitigation}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}   