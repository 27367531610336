import React, { useEffect, useRef, useState } from 'react';
import * as tf from '@tensorflow/tfjs';
import * as cvstfjs from '@microsoft/customvision-tfjs';

const LiveObjectDetection = () => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [isRunning, setIsRunning] = useState(false);
    const [model, setModel] = useState(null);
    const [labels, setLabels] = useState([]);
    const detectionRef = useRef(null);

    const setupCamera = async () => {
        const video = videoRef.current;
        const stream = await navigator.mediaDevices.getUserMedia({
            video: { width: { ideal: 1280 }, aspectRatio: { ideal: 16 / 9 } }
        });
        video.srcObject = stream;
        return new Promise((resolve) => {
            video.onloadedmetadata = () => {
                resolve(video);
            };
        });
    };

    const drawBoundingBoxes = (predictions, ctx, width, height) => {
        predictions.forEach((prediction) => {
            if (prediction.prob >= 0.3) {
                const [x1, y1, x2, y2] = prediction.bbox;
                const scaledX1 = x1 * width;
                const scaledY1 = y1 * height;
                const scaledX2 = x2 * width;
                const scaledY2 = y2 * height;
                const boxWidth = scaledX2 - scaledX1;
                const boxHeight = scaledY2 - scaledY1;

                console.log(`BBox: x=${scaledX1}, y=${scaledY1}, w=${boxWidth}, h=${boxHeight}`);
                ctx.beginPath();
                ctx.rect(scaledX1, scaledY1, boxWidth, boxHeight);
                ctx.lineWidth = 2;
                ctx.strokeStyle = 'red';
                ctx.fillStyle = 'rgba(255, 0, 0, 0.3)'; // Semi-transparent fill for the bounding box
                ctx.stroke();
                ctx.fillRect(scaledX1, scaledY1, boxWidth, boxHeight); // Fill the bounding box
                ctx.closePath();

                ctx.font = '20px Arial'; // Set the font size and family
                ctx.fillStyle = 'red'; // Opaque color for the text
                ctx.fillText(prediction.label + ' - ' + Math.round(prediction.prob * 100) + '%', scaledX1, scaledY1 > 10 ? scaledY1 - 5 : 10);
            }
        });
    };

    useEffect(() => {
        const loadLabels = async (url) => {
            const response = await fetch(url);
            const text = await response.text();
            const labels = text.split('\n').map(label => label.trim());
            return labels;
        };



        const run = async () => {
            try {
                const model = new cvstfjs.ObjectDetectionModel();
                await model.loadModelAsync('https://fde-dev-saeswdeveastus001-h0hebzdkbwbrazcx.z01.azurefd.net/safeworkdev/AI_PUBLIC_MODEL/model.json');
                const labels = await loadLabels('https://fde-dev-saeswdeveastus001-h0hebzdkbwbrazcx.z01.azurefd.net/safeworkdev/AI_PUBLIC_MODEL/labels.txt');
                setModel(model);
                setLabels(labels);
            } catch (error) {
                console.error('Error loading model or labels:', error);
            }
        };

        run();
    }, []);

    useEffect(() => {
        if (isRunning && model && labels.length > 0) {
            const startDetection = async () => {
                const video = await setupCamera();
                const canvas = canvasRef.current;
                const ctx = canvas.getContext('2d');

                video.addEventListener('loadeddata', async () => {
                    // Set canvas dimensions to match video dimensions
                    canvas.width = video.videoWidth;
                    canvas.height = video.videoHeight;

                    const detect = async () => {
                        if (!isRunning) return;
                        try {
                            const result = await model.executeAsync(video);
                            const [detected_boxes, detected_scores, detected_classes] = result;

                            const predictions = detected_boxes.map((box, i) => ({
                                bbox: box,
                                prob: detected_scores[i],
                                label: labels[detected_classes[i]]
                            })).filter(prediction => prediction.prob >= 0.3);

                            ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear previous drawings
                            drawBoundingBoxes(predictions, ctx, canvas.width, canvas.height);

                            await tf.nextFrame();
                            detectionRef.current = requestAnimationFrame(detect);
                        } catch (error) {
                            console.error('Error during detection:', error);
                        }
                    };

                    detect();
                });
            };

            startDetection();
        } else {
            cancelAnimationFrame(detectionRef.current);
        }
    }, [isRunning, model, labels]);

    const handleStart = () => {
        setIsRunning(true);
    };

    const handleStop = () => {
        setIsRunning(false);
        const video = videoRef.current;
        if (video && video.srcObject) {
            const tracks = video.srcObject.getTracks();
            tracks.forEach(track => track.stop());
            video.srcObject = null;
        }
    };

    return (
        <div className="row videoMain">
            <div className="tile sm-12 md-12 lg-12 xl-12">
                <div className="header">
                    <div className="left">
                        <div className="title">Live Detection</div>
                    </div>
                </div>
                <div className="content">
                    <div className="column">
                        <div className='btnWrapper'>
                            <button className="btn primary" onClick={handleStart} >Start Camera</button>
                            <button className="btn primary" onClick={handleStop} >Stop Camera</button>
                        </div>
                        <div className='videoWrapper'>
                            {isRunning && (
                                <div className='videoCanvas'>
                                    <video id="camera_view" ref={videoRef} autoPlay playsInline></video>
                                    <canvas id="canvas" ref={canvasRef}></canvas>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LiveObjectDetection;