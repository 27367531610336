import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { getUploadFolder } from "../../api/HazardID.api";
import { Select } from '@eds/vanilla';
import Loading from "../../components/Loading/Loading";

export default function HazardIDCreate({ setCreateHazard, setError }) {
    const [getData, setData] = useState({
        siteID: "",
        siteType: "",
        customer: ""
    });
    const [isLoading, setIsLoading] = useState({
        folderUploadLoading: false,
    });
    const [errsMsg, setErrors] = useState({
        siteID: "",
        folderUploadLoading: "",
    });
    const { headers } = useSelector((state) => state.envVariables);

    useEffect(() => {
        const selectDOM = document.querySelectorAll(".select");
        let select;
        Array.from(selectDOM).forEach((selectDOM) => {
            select = new Select(selectDOM);
            select.init();
        });

        return () => {
            select.destroy();
        }
    }, []);

    const handleFileUpload = async (event) => {
        const fileInput = event[0];
        if (!fileInput) {
            return;
        }
        else {
            try {
                const formData = new FormData();
                formData.append("archive", fileInput);
                await getUploadFolder(headers, formData, getData, setIsLoading, setError, setCreateHazard);
            } catch (error) {
                console.error("Error resizing image:", error);
            }
        }
    };

    const handleChange = (event, k) => {
        const { name, value } = event.target;
        if (name === "siteID") {
            if (value === "") {
                setErrors({ ...errsMsg, [name]: "Please enter site - ID" });
            } else {
                setErrors({ ...errsMsg, [name]: "" });
            }
            setData({ ...getData, [name]: value });
        }
    }



    const SiteTypeOption = ['In-Building / Data Center', 'Guyed', 'Ground', 'Monopole', 'Rooftop', 'Self Support', 'Shelter', 'Stealth Pole', 'Tower', 'Utility', 'Other'];
    const customerOption = ['Airwavz (Emerging Business)', 'Arelion', 'AT-T', 'COVID-19 Reports', 'Diamond (Emerging Business)', 'Google', 'IVC (Illinois Valley Cellular)', 'Pine Cellular', 'Rogers', 'T-Mobile', 'Totem (Emerging Business)', 'US Cellular Corp', 'Verizon', 'Viaero', 'Other'];

    const onCloseHandler = () => {
        setCreateHazard(false);
    };

    return (
        <>
            <div className="dialog HazardCreate show modal-md" data-trigger="#HazardCreate" data-type="simple">
                <div className="content">
                    <div className="top">
                        <div className="title">Site Survey Information</div>
                        <div className="right">
                            <button className="btn-icon" data-close="true" onClick={() => onCloseHandler()}>
                                <i className="icon icon-cross"></i>
                            </button>
                        </div>
                    </div>
                    <div className="body">
                        <div className="tile sm-12 md-12 lg-12 xl-12 createHazardEle">
                            {isLoading.folderUploadLoading ? (
                                <div className="centerLoader">
                                    <Loading />
                                </div>
                            ) :
                                (<div className="row card">
                                    <div className="column  md-12 sm-12 lg-12">
                                        <div className="formEle">
                                            <div className="form-element">
                                                <div className="inputText">
                                                    <label htmlFor="input-1" className="required">
                                                        <span>Enter site ID</span>
                                                    </label>
                                                    <input type="text" className={errsMsg.siteID !== "" ? "invalid validation" : "validation"} name="siteID" value={getData.siteID} onChange={(event) => handleChange(event)} id="input-1" placeholder="Enter site - ID" />
                                                    <span className="validation-msg error-message-required">{errsMsg.siteID}</span>
                                                </div>
                                            </div>

                                            <div className="form-element dropDiv">
                                                <div className="dropDownDiv">
                                                    <label className="required">Please select site type</label>
                                                    <div className="select" data-type="single">
                                                        <button className="btn current-options" type="button">Select option</button>
                                                        <div className="options-list">
                                                            {SiteTypeOption.map((i, k) => {
                                                                return (
                                                                    <div className="item" key={k} onClick={() => setData({ ...getData, ["siteType"]: i })}>{i}</div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-element dropDiv">
                                                <div className="dropDownDiv">
                                                    <label className="required">Please select customer</label>
                                                    <div className="select" data-type="single">
                                                        <button className="btn current-options" type="button">Select option</button>
                                                        <div className="options-list">
                                                            {customerOption.map((i, k) => {
                                                                return (
                                                                    <div className="item" key={k} onClick={() => setData({ ...getData, ["customer"]: i })}>{i}</div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="column md-12 sm-12 lg-12">
                                        <div className="card drag-and-drop">
                                            <div className="header">
                                                <div className="left">Upload site survey package</div>
                                                <div className="right">
                                                    <i className="icon icon-robot-head" style={{ marginRight: "4px" }}></i>
                                                    Powered by AI
                                                </div>
                                            </div>
                                            <div className="">
                                                {/* <p>Choose file(s) to upload</p> */}
                                                <form className="drag-and-drop-area"
                                                    onDragOver={(event) => { event.preventDefault(); }}
                                                    onDrop={(event) => {
                                                        event.preventDefault(); handleFileUpload(event.dataTransfer.files); event.target.value = null;
                                                    }}>
                                                    <i className="icon icon-upload"></i>
                                                    <p style={{ marginTop: "-13px" }}>
                                                        Drag & drop the compressed file or{" "}
                                                        <label>
                                                            select to browse
                                                            <input
                                                                type="file"
                                                                multiple
                                                                accept=".zip,.rar"
                                                                onChange={(event) => {
                                                                    handleFileUpload(event.target.files); event.target.value = null;
                                                                }}
                                                            />
                                                        </label>
                                                    </p>
                                                    <p>Supported formats: zip, rar
                                                   {/* <br />
                                                     Maximum file size: 500 MB*/}
                                                     </p>
                                                </form>
                                                <div className="header header-files hidden">
                                                    <div className="left">Uploaded folder</div>
                                                </div>
                                                <div className="content-files">
                                                    <div className="file-pills"></div>
                                                    <table className="upload-table"></table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}